import React from 'react';

type LogoProps = {
  className?: string;
};

export default function Logo({ className }: LogoProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 303.69 57.63"
    >
      <path d="M29.51,43.51V18H42.27c5.73,0,8.86,3.86,8.86,8.48S48,35,42.27,35H36.08v8.56Zm14.94-17c0-1.75-1.42-2.75-3.17-2.75h-5.2v5.46h5.2C43,29.22,44.45,28.23,44.45,26.51Z" />
      <path d="M54.08,43.51V18H72.75v5.73H60.64v4H72.48v5.73H60.64v4.32H72.75v5.73Z" />
      <path d="M93.91,43.51,83.14,28.77V43.51H76.57V18h6.76l10.31,14V18h6.61V43.51Z" />
      <path d="M155.47,43.51V18h12.76c5.73,0,8.86,3.86,8.86,8.48S174,35,168.23,35H162v8.56Zm14.94-17c0-1.75-1.42-2.75-3.17-2.75H162v5.46h5.2C169,29.22,170.41,28.23,170.41,26.51Z" />
      <path d="M194.59,43.51l-1.26-3.67H183.28L182,43.51h-7.45L184.16,18h8.29L202,43.51Zm-6.27-19-3.24,9.58h6.45Z" />
      <path d="M203.91,43.51V18h12.75c5.73,0,8.86,3.86,8.86,8.48S222.39,35,216.66,35h-6.18v8.56Zm14.93-17c0-1.75-1.41-2.75-3.17-2.75h-5.19v5.46h5.19C217.43,29.22,218.84,28.23,218.84,26.51Z" />
      <path d="M228.47,43.51V18h18.67v5.73H235v4h11.84v5.73H235v4.32h12.1v5.73Z" />
      <path d="M265.17,43.51,260.9,35h-3.36v8.56H251V18h12.75c5.66,0,8.86,3.75,8.86,8.48a7.67,7.67,0,0,1-5.08,7.64l5.2,9.36Zm.73-17c0-1.75-1.41-2.71-3.17-2.71h-5.19v5.46h5.19C264.49,29.22,265.9,28.27,265.9,26.47Z" />
      <path d="M144.81,44.62a1.72,1.72,0,0,1-2.16.22,15.33,15.33,0,0,0-6.84-3.2c-2.68,7.07-9.74,15-12.34,15.77-4.17,1.26-13.76-3.27-15.62-7.36a10.34,10.34,0,0,1-.3-3.28c0-4,.6-10.18,1.79-11.6,1.93-2.38,8.55-3.42,8.55-3.42s-3-3.27-3.42-6.69c-.22-2,2.16-8.78,5.2-14.73,2.38-4.76,5.28-9.07,7.14-10,3.28-1.57,10.79,2.23,11.53,4.46.52,1.71-1.34,12.42-1.78,13.76a4.79,4.79,0,0,1-1.79,2.53,23.21,23.21,0,0,1-5.28,1.93c-1.19.07-1.11-1.12-.74-2,.3-.67,4.91-10.56,4.91-15.47,0-1.19-.3-2.08-1-2.45-1.79-1.12-6,3.42-9.45,8.48-3,4.38-5.35,9.14-5.5,11.15-.22,3.13,3.57,6.4,5.73,7.36,1.78.75,5.8,1.79,7.88,2.76.6.29,1,.52,1.19.82s-.15.89-.82,1.41a14.55,14.55,0,0,1-3.42,1.56,4.47,4.47,0,0,1-2.08.22A40.25,40.25,0,0,0,116.33,35a5.47,5.47,0,0,0-3.35.82c-.59.52-1,2.31-1,4.54,0,4.68,1.42,11.3,4.09,12.49a8.6,8.6,0,0,0,3.5.82c4.69,0,9.3-4.24,13.83-12.2h-.07a63.43,63.43,0,0,0-9.15,1.27c-.82.15-1.64-.23-1.64-.75,0-.37.45-.74,1.64-1a75.45,75.45,0,0,1,13.68-2.08,48.1,48.1,0,0,1,10.71.74c.3.15.38.45.15.9A38.91,38.91,0,0,1,144.81,44.62Z" />
      <path d="M19.34,32.3H2.53C.53,32.3-.07,31,0,30.59s1.41-1,2.52-1H19.34c2.75,0,3.8.37,3.65.82A4.33,4.33,0,0,1,19.34,32.3Z" />
      <path d="M300,32.3H283.22c-2,0-2.6-1.26-2.53-1.71s1.41-1,2.53-1H300c2.75,0,3.79.37,3.64.82A4.29,4.29,0,0,1,300,32.3Z" />
    </svg>
  );
}
